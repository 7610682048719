<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Course
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Course Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="supervisor"
                v-model="form.subject_id"
                :items="Subjects"
                label="Subject"
                prepend-icon="mdi-book"
                :error-messages="errors.subject_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="6" md="3" lg="3">
              <v-autocomplete
                :items="duration"
                label="Select Duration"
                v-model="form.course_duration"
              ></v-autocomplete>
            </v-col>
            <v-col sm="12 mt-9">
              <h2>Document Upload</h2>
              <br />
              <input
                type="file"
                id="file_document"
                name="file_document"
                ref="file_document"
                @change="DocumentPath"
              />
              <br />
              <v-btn
                v-if="form.document_path != null"
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="
                  ($refs.file_document.value = null), (document_path = null)
                "
                >Remove</v-btn
              >
            </v-col>

            <v-col sm="12 mt-9">
              <h2>Video Upload</h2>
              <br />
              <input
                type="file"
                id="file_video"
                name="file_video"
                ref="file_video"
                @change="VideoPath"
                accept="video/*"
              />
              <br />
              <v-btn
                v-if="form.video_path != null"
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file_video.value = null), (video_path = null)"
                >Remove</v-btn
              >
            </v-col>
            <v-row class="mb-2" style="padding: 12px">
              <v-col sm="12" class="pb-0">
                <h2 class="font-weight-bold mb-2 mt-2">Course Text</h2>
              </v-col>
              <v-col>
                <vue-editor v-model="form.text" class="form-control" />
              </v-col>
            </v-row>
            <v-row class="mb-2" style="padding: 12px">
              <v-col sm="12" class="pb-0">
                <h2 class="font-weight-bold mb-2 mt-2">Certificate Content</h2>
              </v-col>
              <v-col>
                <vue-editor
                  v-model="form.course_content"
                  class="form-control"
                  :error-messages="errors.course_content"
                />
                <div
                  v-if="errors && errors.course_content"
                  class="error-message"
                >
                  <span style="color: red"
                    >certificate content must be between 1 to 400
                    characters.</span
                  >
                </div>
              </v-col>
            </v-row>
            <v-col sm="12">
              <h2>Exam Details</h2>
            </v-col>
            <v-col sm="2">
              <v-text-field
                v-model="form.passing_marks"
                outlined
                label="Passing Percentage *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.passing_marks"
              ></v-text-field>
            </v-col>
            <v-col sm="2">
              <v-text-field
                v-model="form.no_of_attempt"
                outlined
                label="No Of Attempts *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.no_of_attempt"
              ></v-text-field>
            </v-col>
            <v-col sm="2">
              <v-text-field
                v-model="form.exam_duration"
                outlined
                label="Exam Duration*"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.exam_duration"
              ></v-text-field>
            </v-col>
            <v-col sm="2">
              <v-text-field
                v-model="form.no_of_quiz"
                outlined
                label="No of Quiz*"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.no_of_quiz"
              ></v-text-field>
            </v-col>
            <v-col sm="2">
              <v-switch
                v-model="form.is_expire"
                inset
                :label="`Expire: ${form.is_expire == 1 ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save"> Submit </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  name: "CourseCreatePage",
  components: {
    VueEditor,
  },
  data() {
    return {
      form: {
        is_active: true,
        text: "",
      },
      isLoading: false,
      Subjects: [],
      duration: [],
      document_path: "",
      video_path: "",
      error_message: null,
    };
  },

  mounted() {
    this.getMasters();
  },
  methods: {
    DocumentPath(e) {
      const file = e.target.files[0];
      this.document_path = URL.createObjectURL(file);
    },
    VideoPath(e) {
      const file = e.target.files[0];
      this.video_path = URL.createObjectURL(file);
    },
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      masters.subjects.forEach((Subject) => {
        this.Subjects.push({
          id: Subject.id,
          text: Subject.description,
          value: Subject.id,
        });
      });
      masters.duration.forEach((duration) => {
        this.duration.push({
          id: duration.id,
          text: duration.description,
          value: duration.code,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.created_by_id = this.user.id;
        let course = await axios.post("/course", this.form);
        this.course = course.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/course");
      } catch (error) {
        if (error.response && error.response.data.errors) {
          // Assign API errors to errors object for display
          this.error_message = error.response.data.errors;
        } else {
          // Handle other types of errors
          console.error("Error updating course:", error);
        }
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      const CourseId = this.course.id;
      let formData = new FormData();

      formData.append("id", CourseId);
      let document_path = this.$refs.file_document?.files[0];
      formData.append("document_path", document_path);
      let video_path = this.$refs.file_video?.files[0];
      formData.append("path_video", video_path);

      await axios
        .post("video_and_document_upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
