<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4" style="padding: 22px 15px">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Course
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Course Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="supervisor" v-model="form.subject_id" :items="Subjects" label="Subject"
                prepend-icon="mdi-account" :error-messages="errors.subject_id"></v-autocomplete>
            </v-col>
            <v-col sm="6" md="3" lg="3">
              <v-autocomplete
                :items="duration"
                label="Select Duration"
                v-model="form.course_duration"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row style="margin-top: 40px; margin-bottom: 25px">
            <v-col sm="12">
              <h2 class="mb-4 course-header">Course Document</h2>
              <template v-if="form.document_path">
                <template v-if="doc_format.includes(getFileExtension(form.document_path))
            ">
                  IN
                  <VueDocPreview :value="`${mediaUrl}${form.document_path}`" type="office" />
                </template>
                {{ getFileExtension(form.document_path) }}

                <template v-if="getFileExtension(form.document_path) == 'PDF'">
                  <iframe id="fred" style="border: 1px solid #666ccc" title="PDF in an i-Frame"
                    :src="`${mediaUrl}${form.document_path}`" frameborder="1" scrolling="auto" width="100%"
                    height="600px"></iframe>
                </template>
                <a :href="`${mediaUrl}${form.document_path}`" target="_blank">View Document</a>
              </template>
              <input v-if="!form.document_path" type="file" id="file_document" name="file_document" ref="file_document"
                @change="DocumentPath" />
              <v-btn v-if="form.document_path" x-small class="reset_image my-1" color="red" dark
                @click="removeDocument">Remove</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6 mt-9">
              <h2>Video Upload</h2>
              <input v-if="form.video_path == null" type="file" id="file_video" name="file_video" ref="file_video"
                @change="VideoPath" accept="video/*" />
              <video v-if="form.video_path != null" :src="form.video_path ? `${mediaUrl}${form.video_path} ` : null"
                controls width="600" height="400" ref="file_video"></video>
              <br />
              <br />
              <div id="preview" v-if="form.video_path != null">
                <a :href="`${mediaUrl}${form.video_path}`" target="_blank">view Video</a><br />
                <v-btn v-if="form.video_path != null" x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_video.value = null), (form.video_path = null)
            ">Remove</v-btn>
              </div>
            </v-col>
            <v-row class="mb-2" style="padding: 12px">
              <v-col sm="12" class="pb-0">
                <h2 class="font-weight-bold mb-2 mt-2">Course Text</h2>
              </v-col>
              <v-col>
                <vue-editor v-model="form.text" class="form-control" />
              </v-col>
            </v-row>
            <v-row class="mb-2" style="padding: 12px">
              <v-col sm="12" class="pb-0">
                <h2 class="font-weight-bold mb-2 mt-2">Certificate Content</h2>
              </v-col>
              <v-col>
                <vue-editor v-model="form.course_content" class="form-control"
                  :error-messages="errors.course_content" />
                <div v-if="errors && errors.course_content_validate" class="error-message">
                  <span style="color: red">certificate content must be between 1 to 900
                    characters.</span>
                </div>
              </v-col>
            </v-row>
          </v-row>
          <v-row>
            <v-col sm="12">
              <h2>Exam Details</h2>
            </v-col>
            <v-col sm="2">
              <v-text-field v-model="form.passing_marks" outlined label="Passing Percentage *"
                prepend-inner-icon="mdi-buffet" :error-messages="errors.passing_marks"></v-text-field>
            </v-col>
            <v-col sm="2">
              <v-text-field v-model="form.no_of_attempt" outlined label="No Of Attempts *"
                prepend-inner-icon="mdi-buffet" :error-messages="errors.no_of_attempt"></v-text-field>
            </v-col>
            <v-col sm="2">
              <v-text-field v-model="form.exam_duration" outlined label="Exam Duration*" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.exam_duration"></v-text-field>
            </v-col>
            <v-col sm="2">
              <v-text-field v-model="form.no_of_quiz" outlined label="No of Quiz*" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.no_of_quiz"></v-text-field>
            </v-col>
            <v-col sm="2">
              <v-switch v-model="form.is_active" inset
                :label="`Is Active: ${form.is_active == 1 ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
            <v-col sm="2">
              <v-switch v-model="form.is_expire" inset
                :label="`Expire: ${form.is_expire == 1 ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
            <v-col sm="4">
              <b>Upload Course Incharge Signature Image</b>
              <br />
              <input type="file" id="file_1" name="file_1" ref="file_1" accept="image/*" />

              <template v-if="form.incharge_signature_image_path != null">
                <v-img class="mt-2" v-if="form.incharge_signature_image_path != null"
                  :src="mediaUrl + form.incharge_signature_image_path" alt="image 1"
                  style="height: 50%; width: 50% !important" />
                <br />
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.incharge_signature_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_1.value = null),
            (form.incharge_signature_image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-card-text>
              <v-divider class="mt-5 mb-5"> </v-divider>
              <v-progress-circular style="float: right" v-if="isLoading" indeterminate
                color="primary"></v-progress-circular>
              <v-btn v-else style="float: right; padding: 22px 28px" color="primary" dark @click="update">
                Update Course
              </v-btn>
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  name: "CourseUpdate",
  components: {
    VueEditor,
  },
  data() {
    return {
      form: {
        is_active: true,
      },
      duration: [],
      isLoading: false,
      Commercial: false,
      customer_companies: [],
      doc_format: ["DOCX", "PPTX", "XLSX"],
      Subjects: [],
      document_path: "",
      video_path: "",
      showDocumentPreview: true,
      error_message: null,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      masters.subjects.forEach((Subject) => {
        this.Subjects.push({
          id: Subject.id,
          text: Subject.description,
          value: Subject.id,
        });
      });
      masters.duration.forEach((duration) => {
        this.duration.push({
          id: duration.id,
          text: duration.description,
          value: duration.code,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/course/${this.$route.params.courseId}`);
      this.form = form.data.data;
      this.originalDocumentPath = this.form.document_path;
      this.originalVideoPath = this.form.video_path;
      this.isLoading = false;
    },
    DocumentPath(e) {
      const file = e.target.files[0];
      this.document_path = URL.createObjectURL(file);
    },
    VideoPath(e) {
      const file2 = e.target.files[0];
      this.video_path = URL.createObjectURL(file2);
    },
    async update() {
      try {
        this.isLoading = true;
        let course = await axios.patch(
          `/course/${this.$route.params.courseId}`,
          this.form
        );
        this.course = course.data.data;
        await this.handleFileUpload();
        this.$router.push("/course");
        this.isLoading = false;
      } catch (error) {
        if (error.response && error.response.data.errors) {
          // Assign API errors to errors object for display
          this.error_message = error.response.data.errors;
        } else {
          // Handle other types of errors
          console.error("Error updating course:", error);
        }
        this.isLoading = false;
      }
    },
    getFileExtension(path) {
      if (path) {
        let path_array = path.split("/");
        let image_name = path_array[3];
        let extension = image_name?.split(".")[1].toUpperCase();
        return extension;
      }
    },
    removeDocument() {
      this.form.document_path = null;
      this.showDocumentPreview = false;
    },
    async handleFileUpload() {
      try {
        this.isLoading = true;
        let CourseId = this.course.id;
        let formData = new FormData();
        formData.append("id", CourseId);
        if (
          this.$refs.file_document &&
          this.$refs.file_document.files.length > 0
        ) {
          console.log("try Doc");
          let document_path = this.$refs.file_document?.files[0];
          console.log(document_path);
          formData.append("document_path", document_path);
        }
        if (this.video_path != "") {
          console.log('this.originalVideoPath', this.originalVideoPath);
          console.log('this.originalVideoPath', this.video_path);
          let video_path = this.$refs.file_video?.files[0];
          formData.append("path_video", video_path);
        }
        let incharge_signature_image_path = this.$refs.file_1?.files[0];
        console.log(incharge_signature_image_path);
        formData.append(
          "incharge_signature_image_path",
          incharge_signature_image_path
        );
        await axios
          .post("video_and_document_upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
        console.log("DONE");
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
